import React from "react";
import { Link } from "react-router-dom";
import reactHooksBlogImage from "../../assets/reactHooks-Image2.jpg";

const ReactHooks = () => {
  return (
    <section id="react-hooks-blog" className="bg-gray-50 py-24 min-h-screen">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-gray-800 mb-8">
          Understanding React Hooks
        </h2>
        <div className="flex flex-col md:flex-row md:justify-center">
          <div className="w-full md:w-3/4 lg:w-2/3 bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="flex justify-center bg-gray-200">
              <img
                src={reactHooksBlogImage}
                alt="React Hooks"
                className="rounded-t-lg w-full h-60 object-cover"
              />
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Introduction
              </h3>
              <p className="text-lg text-gray-700 mb-6">
                React Hooks have transformed the way to manage components in
                React. Hooks have significantly improved code readability and
                maintainability. In this blog post, we'll delve into the core
                hooks as well as some newer additions to the React ecosystem.
                Let's dive in!
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                1. <code className="text-gray-800">useState</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useState</code> hook adds
                state management to functional components. It returns an array
                containing the current state value and a function to update it.
                This hook is fundamental for handling state within functional
                components. t provides a clear and declarative API to maintain
                and update state across renders, making it easier to reason
                about component behavior and logic.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useState } from 'react';

const Counter = () => {
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>Count: {count}</p>
      <button onClick={() => setCount(count + 1)}>Increment</button>
    </div>
  );
};

export default Counter;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example demonstrates how{" "}
                <code className="text-blue-600">useState</code> manages and
                updates state in a functional component.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                2. <code className="text-gray-800">useEffect</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useEffect</code> hook allows
                you to perform side effects in functional components. These
                effects can include data fetching, subscriptions, or manual DOM
                manipulations.It runs after the component renders, and its
                behavior is controlled via a dependency array. For example,
                useEffect(() => {/* side effect */}, [dependencies]); ensures
                the effect runs only when specified dependencies change,
                optimizing performance and reducing unnecessary operations.
              </p>
              <p className="text-base text-gray-600 mb-4">
                Unlike lifecycle methods in class components, which are tied to
                specific stages (e.g., componentDidMount), useEffect combines
                these behaviors into a single hook, providing a more unified
                approach to managing side effects. It simplifies the process of
                synchronizing state with external systems or resources, offering
                a cleaner and more intuitive way to handle component side
                effects.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useEffect, useState } from 'react';

const DataFetcher = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.example.com/data');
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div>
      {data ? <pre>{JSON.stringify(data, null, 2)}</pre> : <p>Loading...</p>}
    </div>
  );
};

export default DataFetcher;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example showcases how{" "}
                <code className="text-blue-600">useEffect</code> fetches data
                from an API when the component mounts and updates the state
                accordingly.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                3. <code className="text-gray-800">useContext</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useContext</code> hook
                enables you to access context directly in functional components,
                facilitating the sharing of data across the component tree
                without manually passing props down the hierarchy.
              </p>

              <p className="text-base text-gray-600 mb-4">
                This hook streamlines context consumption, making it more
                accessible and less verbose compared to traditional context
                methods. It eliminates the need for nested context consumers or
                higher-order components, enabling a cleaner and more
                maintainable approach to global state management.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

const ThemedComponent = () => {
  const theme = useContext(ThemeContext);

  return (
    <div style={{ background: theme.background, color: theme.color }}>
      This is a themed component
    </div>
  );
};

const App = () => {
  const [theme, setTheme] = useState({ background: 'black', color: 'white' });

  return (
    <ThemeContext.Provider value={theme}>
      <ThemedComponent />
      <button onClick={() => setTheme({ background: 'white', color: 'black' })}>
        Switch Theme
      </button>
    </ThemeContext.Provider>
  );
};

export default App;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                Here, <code className="text-blue-600">useContext</code> is used
                to access and apply a theme context, demonstrating the
                management of global state with context.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                4. <code className="text-gray-800">useRef</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useRef</code> hook allows
                you to persist values across renders without causing re-renders,
                which is especially useful for accessing and interacting with
                DOM elements directly. By creating a mutable reference with
                const myRef = useRef(initialValue);, developers can store and
                manipulate values that do not need to trigger a re-render when
                updated.
              </p>
              <p className="text-base text-gray-600 mb-4">
                This hook is often employed for managing focus, measuring
                element dimensions, or integrating with third-party libraries
                that require direct DOM access. Unlike state, updating a ref
                does not cause the component to re-render, making it ideal for
                scenarios where you need to maintain mutable values without
                impacting rendering performance.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useRef } from 'react';

const FocusInput = () => {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.focus();
  };

  return (
    <div>
      <input ref={inputRef} type="text" />
      <button onClick={handleClick}>Focus the input</button>
    </div>
  );
};

export default FocusInput;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example demonstrates how{" "}
                <code className="text-blue-600">useRef</code> can be used to
                directly manipulate a DOM element, such as focusing an input
                field.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                5. <code className="text-gray-800">useCallback</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useCallback</code> hook
                returns a memoized version of the callback function that only
                changes if one of the dependencies has changed. It helps to
                avoid unnecessary renders of child components that rely on
                functions as props. This is particularly beneficial for
                optimizing performance when passing callbacks to child
                components that rely on referential equality to prevent
                unnecessary re-renders.
              </p>
              <p className="text-base text-gray-600 mb-4">
                By avoiding the creation of new function instances on each
                render, useCallback helps maintain efficient rendering and
                avoids performance pitfalls in complex component trees. It is a
                key tool for enhancing performance in React applications,
                especially when dealing with large or frequently updated lists.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useCallback, useState } from 'react';

const MemoizedButton = React.memo(({ onClick }) => {
  console.log('Button rendered');
  return <button onClick={onClick}>Click me</button>;
});

const ParentComponent = () => {
  const [count, setCount] = useState(0);

  const handleClick = useCallback(() => {
    console.log('Button clicked');
  }, []); // Dependencies can be added as needed

  return (
    <div>
      <MemoizedButton onClick={handleClick} />
      <p>Count: {count}</p>
      <button onClick={() => setCount(count + 1)}>Increment</button>
    </div>
  );
};

export default ParentComponent;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example highlights how{" "}
                <code className="text-blue-600">useCallback</code> can be used
                to memoize a callback function, reducing unnecessary renders of
                the <code className="text-blue-600">MemoizedButton</code>{" "}
                component.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                6. <code className="text-gray-800">useMemo</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useMemo</code> hook returns
                a memoized value that recalculates only when one of its
                dependencies changes. It helps optimize performance by avoiding
                expensive calculations on every render.
              </p>

              <p className="text-base text-gray-600 mb-4">
                This hook is particularly useful for preventing unnecessary
                recalculations in scenarios where computations are
                resource-intensive or data-heavy. It provides a mechanism to
                optimize rendering performance and enhance the efficiency of
                React applications by reducing redundant calculations.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useMemo, useState } from 'react';

const ExpensiveComponent = ({ number }) => {
  const computeExpensiveValue = (num) => {
    // Simulate an expensive calculation
    let result = 0;
    for (let i = 0; i < 1e6; i++) {
      result += num;
    }
    return result;
  };

  const expensiveValue = useMemo(() => computeExpensiveValue(number), [number]);

  return <div>Expensive Value: {expensiveValue}</div>;
};

const ParentComponent = () => {
  const [number, setNumber] = useState(0);

  return (
    <div>
      <ExpensiveComponent number={number} />
      <button onClick={() => setNumber(number + 1)}>Increment Number</button>
    </div>
  );
};

export default ParentComponent;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                Here, <code className="text-blue-600">useMemo</code> is used to
                memoize the result of an expensive computation, ensuring it is
                recalculated only when the{" "}
                <code className="text-blue-600">number</code> prop changes.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                7. <code className="text-gray-800">Custom Hooks</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                Custom hooks are a powerful feature in React that allow
                developers to encapsulate and reuse stateful logic across
                different components. By defining a function that uses built-in
                hooks, developers can create reusable logic that can be shared
                across multiple components. For instance, function
                useCustomHook() {/* custom logic */} can manage a specific
                aspect of state or side effects and return useful data or
                functions.
              </p>
              <p className="text-base text-gray-600 mb-4">
                Custom hooks promote code reusability and separation of
                concerns, enabling developers to keep components clean and
                focused on their primary responsibilities. They provide a
                modular approach to logic management, facilitating better
                organization and maintainability of complex React applications.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
};

export default useLocalStorage;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This custom hook,{" "}
                <code className="text-blue-600">useLocalStorage</code>,
                synchronizes state with{" "}
                <code className="text-blue-600">localStorage</code>, enabling
                persistent state across page reloads.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                8. <code className="text-gray-800">useActionState</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useActionState</code> hook
                is designed to manage states related to asynchronous actions,
                such as handling different stages of a request (e.g., loading,
                success, failure). It provides a structured way to track the
                status of actions and respond accordingly.
              </p>
              <p className="text-base text-gray-600 mb-4">
                This hook simplifies the management of complex asynchronous
                operations by encapsulating related states and logic, making it
                easier to handle various outcomes of asynchronous actions and
                ensure a consistent user experience.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useActionState } from 'react';

const FetchData = () => {
  const [state, setState] = useActionState({
    loading: false,
    data: null,
    error: null,
  });

  const fetchData = async () => {
    setState({ loading: true });
    try {
      const response = await fetch('https://api.example.com/data');
      const result = await response.json();
      setState({ data: result });
    } catch (error) {
      setState({ error: error.message });
    } finally {
      setState({ loading: false });
    }
  };

  return (
    <div>
      <button onClick={fetchData}>Fetch Data</button>
      {state.loading && <p>Loading...</p>}
      {state.error && <p>Error: {state.error}</p>}
      {state.data && <pre>{JSON.stringify(state.data, null, 2)}</pre>}
    </div>
  );
};

export default FetchData;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example demonstrates how{" "}
                <code className="text-blue-600">useActionState</code> manages
                loading, error, and success states in an asynchronous operation.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                9. <code className="text-gray-800">useFormStatus</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useFormStatus</code> hook
                manages form states, such as validation and submission status.
                It simplifies form logic and provides a structured way to handle
                form interactions.
              </p>
              <p className="text-base text-gray-600 mb-4">
                This hook enhances user experience by offering a clear and
                consistent way to manage form-related feedback, making it easier
                to display appropriate messages or UI updates based on the
                form's current status.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useFormStatus } from 'react';

const FormComponent = () => {
  const [formStatus, setFormStatus] = useFormStatus({
    submitting: false,
    error: null,
    success: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormStatus({ submitting: true });

    try {
      // Simulate form submission
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setFormStatus({ success: 'Form submitted successfully!' });
    } catch (error) {
      setFormStatus({ error: 'Submission failed' });
    } finally {
      setFormStatus({ submitting: false });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="name" required />
      <button type="submit" disabled={formStatus.submitting}>Submit</button>
      {formStatus.submitting && <p>Submitting...</p>}
      {formStatus.error && <p>Error: {formStatus.error}</p>}
      {formStatus.success && <p>{formStatus.success}</p>}
    </form>
  );
};

export default FormComponent;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                In this example,{" "}
                <code className="text-blue-600">useFormStatus</code> manages the
                submission status of a form, providing feedback based on the
                form's state.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                10. <code className="text-gray-800">useOptimistic</code>
              </h3>
              <p className="text-base text-gray-600 mb-4">
                The <code className="text-blue-600">useOptimistic</code> hook
                handles optimistic UI updates by temporarily assuming a
                successful outcome for user actions and rolling back if
                necessary. This hook provides immediate feedback by updating the
                UI optimistically while waiting for server confirmation. For
                example, const [state, updateState] =
                useOptimistic(initialState, optimisticUpdateFunction); allows
                for seamless user interactions and efficient state management.
              </p>
              <p className="text-base text-gray-600 mb-4">
                Optimistic updates improve user experience by providing instant
                feedback, reducing perceived latency, and managing potential
                rollbacks if the optimistic assumption does not hold. This
                approach enhances the responsiveness and interactivity of
                applications, particularly in scenarios involving asynchronous
                data operations.
              </p>
              <pre className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                <code className="text-gray-800">
                  {`import React, { useOptimistic } from 'react';

const OptimisticUpdate = () => {
  const [count, setCount] = useOptimistic(0);

  const handleClick = async () => {
    setCount(count + 1);
    try {
      // Simulate a server request
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      // Roll back to the previous state if needed
      setCount(count);
    }
  };

  return (
    <div>
      <p>Count: {count}</p>
      <button onClick={handleClick}>Increment</button>
    </div>
  );
};

export default OptimisticUpdate;`}
                </code>
              </pre>
              <p className="text-base text-gray-600">
                This example demonstrates{" "}
                <code className="text-blue-600">useOptimistic</code> for
                optimistic UI updates, improving perceived performance by
                updating the UI before server confirmation.
              </p>

              <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                Conclusion
              </h3>
              <p className="text-base text-gray-600 mb-4">
                React Hooks have transformed the way we manage state and side
                effects in functional components. By leveraging core hooks like{" "}
                <code className="text-blue-600">useState</code> and{" "}
                <code className="text-blue-600">useEffect</code>, as well as
                newer additions like{" "}
                <code className="text-blue-600">useActionState</code>,{" "}
                <code className="text-blue-600">useFormStatus</code>, and{" "}
                <code className="text-blue-600">useOptimistic</code>, developers
                can create more efficient and maintainable applications. Embrace
                these hooks to enhance your React projects and streamline your
                development workflow.
              </p>
              <p className="text-base text-gray-600 mb-4">
                Whether you're working on a new project or refactoring an
                existing one, understanding and utilizing these hooks will help
                you write cleaner, more responsive code.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Link
          to="/blog"
          className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          Read More Blog Posts
        </Link>
      </div>
    </section>
  );
};

export default ReactHooks;
