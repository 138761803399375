import React from "react";
import { Link } from "react-router-dom";
import datBlogImage from "../../assets/DAT-blog-Image.png";

const DebouncingAndThrottlingBlog = () => {
  return (
    <section
      id="debouncing-throttling-blog"
      className="bg-gray-50 py-12 md:py-24 min-h-screen"
    >
      <div className="container mx-auto px-4 md:px-8">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-gray-800 mb-8">
          Debouncing and Throttling in JavaScript
        </h2>

        <div className="flex justify-center mb-8">
          <img
            src={datBlogImage}
            alt="Blog Post"
            className="rounded-lg shadow-lg"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Introduction
          </h3>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            Have you ever encountered a scenario in your web app where user
            actions—like typing in a search box or scrolling—cause functions to
            fire off every single time? It can lead to frustrating performance
            issues or even make your app sluggish. Today, we’re diving into two
            powerful techniques to manage these issues:{" "}
            <strong>debouncing</strong> and <strong>throttling</strong>. Let’s
            explore these concepts, their differences, and when to use each one
            effectively.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Understanding Debouncing
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Debouncing is a technique used to limit the rate at which a function
            is executed. It ensures that a function runs only after a certain
            period of inactivity following the last invocation.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Imagine youre building a search feature for your web app. Every time
            a user types a character, you want to fetch search results from an
            API. Without debouncing, each keystroke triggers a separate API
            request, which can overwhelm your server and slow down the user
            experience. Debouncing helps by waiting until the user has paused
            typing before making the request.
          </p>

          <h4 className="text-xl font-semibold text-gray-800 mb-4">
            Debouncing Example
          </h4>
          <p className="text-lg text-gray-700 mb-4">
            Here’s a JavaScript example to illustrate debouncing:
          </p>

          <div className="bg-gray-100 p-4 rounded-lg mb-6 overflow-x-auto">
            <pre className="text-gray-800">
              <code>{`
function debounce(func, delay) {
  let timeoutId;
  
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  }
}

const searchInput = document.getElementById('search-input');

function handleSearch() {
  // Perform search operation
}

const debouncedSearch = debounce(handleSearch, 300);

searchInput.addEventListener('input', debouncedSearch);
              `}</code>
            </pre>
          </div>

          <p className="text-lg text-gray-700 mb-6">
            In this example, we have an input field with an event listener
            attached to it. The <code>handleSearch</code> function is debounced
            using the <code>debounce</code> function, which ensures it is called
            only after 300 milliseconds of inactivity. This prevents the
            function from being called too frequently and helps improve
            performance.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            What is Throttling?
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Throttling, on the other hand, controls how often a function is
            executed, irrespective of how frequently the events occur. It’s
            particularly useful for preventing performance issues caused by
            excessive function calls or to limit resource usage.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            For instance, when tracking a user’s scroll position to update a
            progress bar, without throttling, the scroll event function might
            run excessively. Throttling helps by ensuring that the function is
            invoked at fixed intervals, reducing the number of executions.
          </p>

          <h4 className="text-xl font-semibold text-gray-800 mb-4">
            Throttling Example
          </h4>
          <p className="text-lg text-gray-700 mb-4">
            Here’s how you can implement throttling in JavaScript:
          </p>

          <div className="bg-gray-100 p-4 rounded-lg mb-6 overflow-x-auto">
            <pre className="text-gray-800">
              <code>{`
function throttle(func, limit) {
  let inThrottle = false;
  
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  }
}

function handleScroll() {
  // Perform scroll-related operations
}

const throttledScroll = throttle(handleScroll, 200);

window.addEventListener('scroll', throttledScroll);
              `}</code>
            </pre>
          </div>

          <p className="text-lg text-gray-700 mb-6">
            This example shows a scroll event listener on the window object. The{" "}
            <code>handleScroll</code> function is throttled using the{" "}
            <code>throttle</code> function, ensuring it is called at most once
            every 200 milliseconds. This approach helps in improving performance
            and preventing UI issues caused by frequent function invocations.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Debounce vs Throttle: When to Use Each
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Both debouncing and throttling limit function invocations but in
            different ways.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            <strong>Debouncing</strong> is ideal when you want a function to run
            only after a series of rapid events have stopped. For instance,
            debouncing is perfect for search inputs where you want to wait until
            the user finishes typing before making an API call.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            <strong>Throttling</strong> is best when you need to ensure a
            function is executed at regular intervals, even if events occur more
            frequently. This is useful for events like scrolling or resizing,
            where continuous actions can overwhelm your application.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Implementation Example: Debounce vs Throttle
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            To illustrate the practical application, let’s look at a
            comprehensive example where both techniques are used. Below is a
            simple HTML setup that demonstrates both debouncing and throttling.
          </p>

          <div className="bg-gray-100 p-4 rounded-lg mb-6 overflow-x-auto">
            <pre className="text-gray-800">
              <code>{`
<!DOCTYPE html>
<html>
  <head>
    <title>Debounce VS Throttle</title>
    <meta charset="UTF-8" />
  </head>
  <body>
    <div id="container">
      <input type="text" />
      <div>
        <b>Default Text:</b>
        <span id="defaultText"></span>
      </div>
      <div>
        <b>Debounce Text:</b>
        <span id="debounceText"></span>
      </div>
      <div>
        <b>Throttle Text:</b>
        <span id="throttleText"></span>
      </div>
    </div>
    <script src="src/index.js"></script>
  </body>
</html>
              `}</code>
            </pre>
          </div>

          <p className="text-lg text-gray-700 mb-4">
            The HTML code sets up a basic structure with an input field and
            areas to display text updated by default, debounced, and throttled
            functions.
          </p>

          <div className="bg-gray-100 p-4 rounded-lg mb-6 overflow-x-auto">
            <pre className="text-gray-800">
              <code>{`
const input = document.querySelector("input");
const defaultText = document.getElementById("defaultText");
const debounceText = document.getElementById("debounceText");
const throttleText = document.getElementById("throttleText");

const updatedDebounceText = debounce((text) => {
  debounceText.textContent = text;
}, 500);

const updatedThrottleText = throttle((text) => {
  throttleText.textContent = text;
}, 500);

input.addEventListener("input", (e) => {
  defaultText.textContent = e.target.value;
  updatedDebounceText(e.target.value);
  updatedThrottleText(e.target.value);
});

function debounce(cb, delay = 1000) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

function throttle(cb, delay = 1000) {
  let shouldWait = false;
  let waitingArgs;
  const timeoutFunction = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      cb(...waitingArgs);
      waitingArgs = null;
      setTimeout(timeoutFunction, delay);
    }
  };

  return (...args) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb(...args);
    shouldWait = true;
    setTimeout(timeoutFunction, delay);
  };
}
              `}</code>
            </pre>
          </div>

          <p className="text-lg text-gray-700 mb-6">
            The JavaScript code demonstrates how to debounce and throttle the
            text update from an input field. It updates text areas to show the
            effects of default, debounced, and throttled input handling.
          </p>

          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <iframe
              src="https://codesandbox.io/embed/javascript-forked-ckwf3p?fontsize=14&hidenavigation=1&theme=dark"
              style={{
                width: "100%",
                height: "500px",
                border: "0",
                borderRadius: "8px",
                overflow: "hidden",
              }}
              title="JavaScript (forked)"
              allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
              sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
            />
          </div>

          <p className="text-lg text-gray-700 mb-6">
            The embedded CodeSandbox example provides a live demo of the
            debounce and throttle implementations in action. You can interact
            with the input field to observe how the default, debounced, and
            throttled text updates behave differently. This hands-on example
            allows you to experiment with the code and see the effects in
            real-time.
          </p>

          <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Wrapping Up
          </h3>
          <p className="text-lg text-gray-700 mb-4">
            Both debouncing and throttling are crucial techniques for optimizing
            performance and enhancing user experience. Mastering when and how to
            use these techniques can significantly improve the responsiveness
            and efficiency of your applications.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Next time you encounter performance issues due to frequent events,
            keep these techniques in mind—they might just be the solution you
            need!
          </p>
          <p className="text-lg text-gray-700">Happy Coding!</p>
        </div>
        <div className="flex justify-center mt-12">
          <Link
            to="/blog"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-200"
          >
            Back to Blogs
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DebouncingAndThrottlingBlog;
