import React from "react";
import { Link } from "react-router-dom";
import blogImage from "../../assets/RPO-image-canva.jpg";

const PerformanceOptimizationBlog = () => {
  return (
    <section
      id="performance-optimization-blog"
      className="bg-gray-50 py-12 md:py-24 min-h-screen"
    >
      <div className="container mx-auto px-4 md:px-8">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-gray-800 mb-8">
          Performance Optimization in React
        </h2>
        <div className="flex flex-col md:flex-row md:justify-center">
          <div className="w-full md:w-3/4 lg:w-2/3 bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="flex justify-center bg-gray-200">
              <img
                src={blogImage}
                alt="Performance Optimization"
                className="object-cover w-full h-64 md:h-80"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            <div className="p-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Introduction
              </h3>
              <p className="text-base text-gray-700 mb-6">
                In web development, <strong>performance optimization</strong> is
                crucial for ensuring a fast and smooth user experience. Users
                expect web apps to be responsive and performant, and any lag can
                lead to frustration and potential abandonment. As React
                developers, we must implement strategies to enhance our app’s
                performance. This comprehensive guide will dive deeper into the
                various techniques to optimize performance in React, providing
                detailed examples and explanations.
              </p>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                1. Minimizing Re-renders with <code>React.memo()</code>
              </h3>
              <p className="text-base text-gray-700 mb-4">
                Let me take you back to a project I worked on last year—a
                dashboard that fetched live data every 5 seconds. It was a
                simple app, but it felt slow and clunky, especially when data
                updates didn’t affect the whole UI. I was scratching my head
                until I discovered how unnecessary re-renders were killing my
                app’s performance. That's when I found out about{" "}
                <code>React.memo()</code>—a lifesaver! When you have components
                that don’t always need to re-render, this higher-order component
                can help you avoid re-renders when the props haven’t changed.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Memoized Component
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`import React from 'react';

const ExpensiveComponent = React.memo(({ data }) => {
  // Simulating a complex calculation or rendering logic
  console.log("Rendering ExpensiveComponent");
  return <div>{data}</div>;
});

export default ExpensiveComponent;`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  In the example above, <code>ExpensiveComponent</code> will
                  only re-render if the <code>data</code> prop changes. This
                  improves performance, particularly when components perform
                  heavy calculations or render large UI elements.
                </p>
              </div>
              <p className="text-base text-gray-700 mb-6">
                <strong>
                  When to Use <code>React.memo()</code>:
                </strong>
                <br />- <strong>Pure Functional Components:</strong> Components
                that render the same output for the same props.
                <br />- <strong>Preventing Unnecessary Re-renders:</strong> When
                a component frequently re-renders with the same props.
              </p>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                2. Lazy Loading Components: Only Load What You Need!
              </h3>
              <p className="text-base text-gray-700 mb-4">
                When building large applications, the size of the initial bundle
                can impact performance. <strong>Lazy loading</strong> allows you
                to defer the loading of components until they are needed. Using{" "}
                <code>React.lazy()</code> in combination with{" "}
                <strong>code splitting</strong>, you can significantly reduce
                the initial load time of your application.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Lazy Loading Components
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`const HeavyComponent = React.lazy(() => import('./HeavyComponent'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <HeavyComponent />
    </Suspense>
  );
}`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  Here, <code>HeavyComponent</code> is lazily loaded when it is
                  needed. The <code>Suspense</code> component provides a
                  fallback UI while the component is being fetched. This
                  technique allows the browser to load smaller chunks of
                  JavaScript, improving overall performance.
                </p>
              </div>
              <p className="text-base text-gray-700 mb-6">
                <strong>Benefits of Lazy Loading:</strong>
                <br />- <strong>Reduced Initial Load Time:</strong> Components
                are loaded only when needed.
                <br />- <strong>Improved User Experience:</strong> Users
                experience faster load times and can interact with parts of the
                UI while additional components load in the background.
              </p>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                3. Leveraging a Content Delivery Network (CDN)
              </h3>
              <p className="text-base text-gray-700 mb-4">
                A <strong>Content Delivery Network (CDN)</strong> enhances the
                performance of your application by serving static assets like
                images, CSS, and JavaScript files from servers located closer to
                users. By distributing content across a global network of
                servers, a CDN reduces latency and increases page load speed.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Serving Static Assets via CDN
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`<link rel="stylesheet" href="https://cdn.example.com/styles.css">
<script src="https://cdn.example.com/scripts.js"></script>
<img src="https://cdn.example.com/images/logo.png" alt="Logo" />`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  With a CDN, these assets are fetched from the nearest server,
                  which reduces the round-trip time (RTT) and speeds up content
                  delivery, particularly for users far from your hosting server.
                </p>
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                4. Memoizing Expensive Calculations with <code>useMemo()</code>
              </h3>
              <p className="text-base text-gray-700 mb-4">
                Just like <code>React.memo()</code> prevents unnecessary
                re-renders, <code>useMemo()</code> helps memoize the results of
                expensive computations. The hook ensures that a function is
                re-executed only when its dependencies change, preventing
                repetitive and costly calculations during each render.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Using <code>useMemo()</code> for Expensive
                  Calculations
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`import React, { useMemo } from 'react';

const ExpensiveComponent = ({ data }) => {
  const expensiveResult = useMemo(() => {
    return data.reduce((acc, item) => acc + item.value, 0); // Example of a complex calculation
  }, [data]);

  return <div>Computed Value: {expensiveResult}</div>;
};

export default ExpensiveComponent;`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  In this example, <code>useMemo()</code> ensures that the
                  complex calculation only happens when the <code>data</code>{" "}
                  dependency changes, optimizing performance.
                </p>
              </div>

              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                5. Server-Side Rendering (SSR)
              </h3>
              <p className="text-base text-gray-700 mb-4">
                <strong>Server-Side Rendering (SSR)</strong> improves both
                performance and SEO by rendering React components on the server
                and sending the HTML to the client. This allows users to see a
                fully-rendered page faster, improving the perceived load time.
                Once the page loads, React takes over and hydrates the
                application on the client side.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Implementing SSR
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`import React from 'react';
import { getServerSideProps } from 'next';

function Page({ data }) {
  return <div>{data}</div>;
}

export async function getServerSideProps() {
  const res = await fetch('https://api.example.com/data');
  const data = await res.json();
  return { props: { data } };
}

export default Page;`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  In this example, data is fetched and rendered on the server,
                  providing a fully constructed page to the client, improving
                  initial load performance.
                </p>
              </div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                6. Virtualized Lists with <code>react-window</code>
              </h3>
              <p className="text-base text-gray-700 mb-4">
                Rendering large lists can be resource-intensive. Instead of
                rendering all items at once, <strong>virtualization</strong>{" "}
                only renders items that are visible in the viewport. This is
                achieved with libraries like <code>react-window</code>, which
                significantly improves the performance of large lists by
                reducing the number of DOM nodes.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Virtualized List with <code>react-window</code>
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`import React from 'react';
import { FixedSizeList as List } from 'react-window';

const Row = ({ index, style }) => (
  <div style={style}>Item {index}</div>
);

const VirtualizedList = () => (
  <List
    height={500}
    width={300}
    itemSize={45}
    itemCount={1000}
    
  >
    {Row}
  </List>
);

export default VirtualizedList;`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  This example uses <code>react-window</code> to render a list
                  with 1000 items, but only the visible ones are rendered, which
                  greatly improves performance.
                </p>
              </div>

              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                7. Code Splitting: Why Load Everything at Once?
              </h3>
              <p className="text-base text-gray-700 mb-4">
                While working on a single-page app, I noticed that the bundle
                size was becoming a major issue. Users had to download a massive
                amount of JavaScript on the first load—most of which they didn't
                even need immediately.
              </p>

              <p className="text-base text-gray-700 mb-4">
                <strong>Code splitting</strong> is a technique to split your
                application's code into smaller chunks that can be loaded on
                demand. This can be achieved using dynamic imports and React's{" "}
                <code>lazy()</code> and <code>Suspense</code> components, which
                allows you to load only the necessary code for a given route or
                component.
              </p>
              <p className="text-base text-gray-700 mb-4">
                {" "}
                By splitting my app into smaller chunks and loading them on
                demand, I significantly reduced the initial bundle size and
                improved loading times.
              </p>

              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Code Splitting with <code>React.lazy()</code>
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`import { lazy, Suspense } from 'react';

const HeavyComponent = lazy(() => import('./HeavyComponent'));

const App = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <HeavyComponent />
  </Suspense>
);

export default App;`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  By splitting your code into smaller chunks and only loading
                  components as they are needed, code splitting improves both
                  load time and performance.
                </p>
              </div>

              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                8. Debouncing and Throttling: Don't Overwhelm the Browser
              </h3>

              <p className="text-base text-gray-700 mb-4">
                Debouncing and throttling are techniques used to limit the
                number of function executions. Debouncing delays a function
                until after a user stops triggering an event, while throttling
                ensures the function runs at most once in a given interval.
                These are essential for handling events like search inputs or
                scrolls.
              </p>
              <p className="text-base text-gray-700 mb-4">
                <strong>Debouncing</strong> is ideal when you want a function to
                run only after a series of rapid events have stopped. For
                instance, debouncing is perfect for search inputs where you want
                to wait until the user finishes typing before making an API
                call.
              </p>
              <p className="text-base text-gray-700 mb-4">
                <strong>Throttling</strong> is best when you need to ensure a
                function is executed at regular intervals, even if events occur
                more frequently. This is useful for events like scrolling or
                resizing, where continuous actions can overwhelm your
                application.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Debouncing an Input Field
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code className="text-gray-800">
                    {`import { useState } from 'react';
import { debounce } from 'lodash';

const SearchInput = () => {
  const [query, setQuery] = useState('');

  const handleSearch = debounce((value) => {
    // Perform search operation
    console.log(value);
  }, 300);

  const handleChange = (event) => {
    setQuery(event.target.value);
    handleSearch(event.target.value);
  };

  return <input type="text" value={query} onChange={handleChange} />;
};`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  Here, the <code>debounce</code> function ensures that the{" "}
                  <code>handleSearch</code> function is triggered only after the
                  user has stopped typing for 300 milliseconds, improving
                  performance by reducing unnecessary API calls.
                </p>
              </div>

              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                9. Avoiding Direct Data Mutations
              </h3>
              <p className="text-base text-gray-700 mb-4">
                Mutating data directly can lead to performance issues and
                unintended side effects. Instead, use immutable data structures
                and methods to ensure that updates are predictable and
                performance is optimized. This approach also helps in debugging
                and ensures that the UI correctly reflects the state of the
                application.
              </p>
              {/* Example */}
              <div className="border rounded-lg overflow-hidden bg-gray-50 mb-6">
                <h4 className="bg-gray-200 p-4 text-lg font-semibold text-gray-800">
                  Example: Avoiding Direct Data Mutations
                </h4>
                <pre className="p-4 bg-gray-100 text-gray-800">
                  <code>
                    {`// Incorrect: Mutating an array directly
const data = [1, 2, 3];
data.push(4);

// Correct: Using immutability
const newData = [...data, 4];`}
                  </code>
                </pre>
                <p className="p-4 text-base text-gray-700">
                  Creating new data structures instead of mutating the original
                  ones ensures React's reconciliation algorithm works
                  efficiently and correctly updates components.
                </p>
              </div>
              <h3 className="mt-4 text-xl font-bold text-gray-800">
                Conclusion
              </h3>
              <p className="mt-2 text-base text-gray-600">
                Optimizing the performance of React applications is essential
                for ensuring smooth user experiences, faster load times, and
                improved scalability. Techniques like memoization (
                <code>React.memo()</code> and <code>useMemo()</code>), lazy
                loading, server-side rendering (SSR), and virtualized lists help
                reduce unnecessary operations and speed up the application.
                Additionally, debouncing and throttling prevent overloading the
                app with too many events.
              </p>
              <p className="mt-2 text-base text-gray-600">
                By implementing these performance optimization strategies, you
                can ensure your React apps are fast, responsive, and scalable,
                delivering top-tier user experiences.
              </p>
              <div className="flex justify-center mt-8">
                <Link
                  to="/"
                  className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
                >
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerformanceOptimizationBlog;
