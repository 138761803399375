import React from "react";
import { Link } from "react-router-dom";
import graphQLBlogImage from "../../assets/graphQL-blog-image.png";

const GraphQL = () => {
  return (
    <section id="graphql-blog" className="bg-gray-100 py-24 min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-gray-800">
          Introduction to GraphQL
        </h2>
        <div className="flex flex-wrap justify-center mt-12">
          <div className="w-full md:w-2/3 p-4">
            <div className="bg-white rounded-lg shadow-md">
              <div className="flex justify-center">
                <img
                  src={graphQLBlogImage}
                  alt="GraphQL logo"
                  className="rounded-t-lg"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
              <div className="p-4">
                <p className="mt-2 text-base text-gray-600">
                  GraphQL is a powerful query language designed for APIs. It offers a more efficient and flexible approach to data fetching compared to traditional RESTful APIs, transforming how data is queried and manipulated in web applications.
                </p>

                <h3 className="mt-4 text-xl font-bold text-gray-800">
                  The Problem with REST
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  RESTful APIs, while foundational, often lead to issues such as over-fetching and under-fetching of data. Fixed endpoints return predefined data structures, which can force clients to make multiple requests to gather all necessary information, impacting performance and increasing network overhead.
                </p>

                <h3 className="mt-4 text-xl font-bold text-gray-800">
                  How GraphQL Solves the Problem
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  GraphQL solves these issues by allowing clients to request exactly the data they need. Clients send a single query specifying the desired fields, and the server responds with only that data, thus reducing over-fetching and minimizing network requests.
                </p>
                <p className="mt-2 text-base text-gray-600">
                  Additionally, GraphQL enables clients to fetch multiple resources in a single request and includes a type system to validate queries against a server-defined schema, enhancing robustness and reducing runtime errors.
                </p>
                
                <h3 className="mt-4 text-xl font-bold text-gray-800">
                  Code Examples
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  Let’s dive into some examples to see GraphQL in action. I’ll also share a story about a time when using GraphQL really saved the day.
                </p>

                {/* Query Example */}
                <div className="border rounded-lg overflow-hidden mt-4">
                  <h4 className="bg-gray-200 p-3 text-lg font-bold text-gray-800">
                    Query Example
                  </h4>
                  <pre className="p-4">
                    <code className="text-gray-800">
                      {`query {
  user(id: "123") {
    name
    email
    posts {
      title
      content
    }
  }
}`}
                    </code>
                  </pre>
                  <p className="p-4 text-base text-gray-600">
                    This query retrieves the name and email of a user with ID "123" and includes the titles and content of their posts. It’s a perfect example of how GraphQL allows you to get precisely the data you need in one request.
                  </p>
                </div>

                {/* Mutation Example */}
                <div className="border rounded-lg overflow-hidden mt-4">
                  <h4 className="bg-gray-200 p-3 text-lg font-bold text-gray-800">
                    Mutation Example
                  </h4>
                  <pre className="p-4">
                    <code className="text-gray-800">
                      {`mutation {
  createPost(input: {
    title: "New Post"
    content: "This is a blog post about GraphQL."
    author: "Rajat Saraswat"
  }) {
    id
    title
    content
  }
}`}
                    </code>
                  </pre>
                  <p className="p-4 text-base text-gray-600">
                    This mutation creates a new post with the specified details and returns the ID, title, and content of the created post. It’s a great way to see how mutations can be used to modify data.
                  </p>
                </div>

                <h3 className="mt-4 text-xl font-bold text-gray-800">
                  How to Use and Implement GraphQL
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  Implementing GraphQL involves several steps. Here’s a detailed guide to get you started, complete with tips based on my own experiences:
                </p>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  1. Define Your GraphQL Schema
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  The schema is crucial, acting as a contract between your client and server. It defines the data types, queries, mutations, and subscriptions available. I remember setting up my first schema—it felt like creating a map for the data I wanted to access. Here’s a basic example:
                </p>
                <pre className="border rounded-lg p-4 bg-gray-100">
                  <code className="text-gray-800">
                    {`type User {
  id: ID!
  name: String!
  email: String!
  posts: [Post!]!
}

type Post {
  id: ID!
  title: String!
  content: String!
  author: User!
}

type Query {
  user(id: ID!): User
}

type Mutation {
  createPost(input: CreatePostInput!): Post
}

input CreatePostInput {
  title: String!
  content: String!
  author: String!
}`}
                  </code>
                </pre>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  2. Set Up a GraphQL Server
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Choose a server framework that fits your stack. For instance, I used Apollo Server for a Node.js project, which made setting up the server straightforward. Here’s a simple example:
                </p>
                <pre className="border rounded-lg p-4 bg-gray-100">
                  <code className="text-gray-800">
                    {`const { ApolloServer, gql } = require('apollo-server');

const typeDefs = gql\`
  type Query {
    hello: String
  }
\`;

const resolvers = {
  Query: {
    hello: () => 'Hello world!',
  },
};

const server = new ApolloServer({ typeDefs, resolvers });

server.listen().then(({ url }) => {
  console.log(\`🚀 Server ready at {url}\`);
});`}
                  </code>
                </pre>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  3. Define Resolvers
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Resolvers are the functions that fetch and return the data for your queries and mutations. Each field in your schema needs a corresponding resolver. When I first started working with resolvers, I was surprised at how they effectively bridge the gap between the schema and the actual data.
                </p>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  4. Handle Mutations and Subscriptions
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Define mutations to modify data and subscriptions to handle real-time updates. Here’s a sample subscription query:
                </p>
                <pre className="border rounded-lg p-4 bg-gray-100">
                  <code className="text-gray-800">
                    {`subscription {
  postAdded {
    id
    title
    content
  }
}`}
                  </code>
                </pre>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  5. Set Up a GraphQL Client
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Use libraries like Apollo Client, Relay, or urql on the client-side to interact with your GraphQL server. I remember using Apollo Client for the first time and being impressed by how it simplified querying and caching.
                </p>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  6. Write GraphQL Queries and Mutations
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Start writing queries and mutations to interact with your GraphQL API. I remember the satisfaction of seeing my queries fetch data exactly as expected.
                </p>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  7. Handle Real-Time Updates with Subscriptions
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  If your server supports subscriptions, set up event listeners on the client-side to receive real-time updates. It’s like having a live feed of data changes.
                </p>

                <h3 className="mt-4 text-xl font-bold text-gray-800">Pros and Cons</h3>
                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  Pros
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  GraphQL offers several advantages:
                </p>
                <ul className="list-disc list-inside mt-2 text-gray-600">
                  <li>Efficient Data Fetching: Request exactly the data needed, avoiding over-fetching and under-fetching.</li>
                  <li>Single Endpoint: Simplify API management with a single endpoint, avoiding versioning issues.</li>
                  <li>Strongly Typed Schema: Clear documentation and validation through a defined schema.</li>
                  <li>Real-time Data: Support for real-time updates with subscriptions.</li>
                  <li>Tooling and Ecosystem: Rich ecosystem of tools and libraries for development and integration.</li>
                </ul>

                <h4 className="mt-4 text-lg font-bold text-gray-800">
                  Cons
                </h4>
                <p className="mt-2 text-base text-gray-600">
                  Despite its benefits, GraphQL has some drawbacks:
                </p>
                <ul className="list-disc list-inside mt-2 text-gray-600">
                  <li>Overfetching: Potential to return more data than needed in some cases.</li>
                  <li>Learning Curve: Requires learning new concepts and best practices.</li>
                  <li>Server Complexity: Can add complexity compared to RESTful APIs.</li>
                  <li>Cache Management: More challenging due to flexible query structure.</li>
                </ul>

                <h3 className="mt-4 text-xl font-bold text-gray-800">
                  Conclusion
                </h3>
                <p className="mt-2 text-base text-gray-600">
                  GraphQL offers a robust and flexible alternative to traditional REST APIs, allowing for efficient data fetching and real-time updates. It's a great choice for modern applications, though it’s important to assess its fit for your specific use case and be prepared for a learning curve.
                </p>
                <p className="mt-2 text-base text-gray-600">
                  Overall, integrating GraphQL can significantly enhance your API interactions, making it a worthy consideration for future projects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Link
          to="/blog"
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Read More Blog Posts
        </Link>
      </div>
    </section>
  );
};

export default GraphQL;
